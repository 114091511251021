
/* eslint-disable */

import React, { Component, Suspense, lazy, useState } from "react";

import { connect } from "react-redux";
//import { Route, Router, BrowserRouter, Switch,Redirect,useHistory,withRouter,Link } from 'react-router-dom';
import { Route, BrowserRouter, Switch } from "react-router-dom";

import CloseIcon from "@material-ui/icons/Close";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import closeicon from "./assets/images/close1.svg";
import moment from "moment/moment";
require('dotenv').config();
import $ from "jquery";
import { LeadEdit } from "./actions/AdminLead";
/*import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/js/bootstrap.min.js";*/

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { login } from "./actions/auth";
import jwtDecode from "jwt-decode";
//import EnquiryForm from "./components/EnquiryForm";
//import SuperAdminDashboard from "./components/SuperAdmin/SuperAdminDashboard";
// import Drive from './components/admin/DriveList';
// import RoleList from './components/admin/RoleList';
//import $ from 'jquery';
//import Popper from 'popper.js';
import { AdminInfo } from "./actions/AdminLogin";
import { AdminLogout } from "./actions/AdminLogin";
import { fetchToken, onMessageListener } from "./firebase";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Profile from "./assets/images/profile(1).svg";
//import { withRouter } from 'react-router-dom';
/*import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/custom.css";
import "./App.css";*/
import { ProductList } from "./actions/AdminProduct";
import { FieldEnggList } from "./actions/AdminFieldEngg";
import { LeadStatusList ,LeadUpdate} from "./actions/AdminLead";

//import { clearMessage } from "./actions/message";

//import { history } from './helpers/history';

const Login = lazy(() => import("./components/Login"));
const UserList = lazy(() => import("./components/admin/UserList"));
const Dashboard = lazy(() => import("./components/admin/Dashboard"));
// const LeadList =lazy(() =>import ('./components/admin/LeadList'));
const ProductListcomp = lazy(() => import("./components/admin/ProductList"));
const DepartmentList = lazy(() => import("./components/admin/DepartmentList"));
const Lead = lazy(() => import("./components/admin/LeadList"));
const FieldEngg = lazy(() => import("./components/admin/FieldEngg"));
const StaffListV1 = lazy(() => import("./components/admin/FieldEnggV1"));
const SlotList = lazy(() => import("./components/admin/SlotList"));
const SlotListV1 = lazy(() => import("./components/admin/SlotList_v1"));
const TemplateList = lazy(() => import("./components/admin/TemplateList"));
const RoleList = lazy(() => import("./components/admin/RoleList"));
const CampaignList = lazy(() => import("./components/admin/CampaignList"));
const DriveList = lazy(() => import("./components/admin/DriveList"));
const ReportList = lazy(() => import("./components/admin/ReportList"));
const ReportListAppointment = lazy(() => import("./components/admin/ReportListAppointment"));
const ProductRevenueReport = lazy(() => import("./components/admin/ProductRevenue"));
const DTMFList = lazy(() => import("./components/admin/DTMFList"));
const Notifications = lazy(() => import("./components/admin/Notifications"))
const SuperAdminLogin = lazy(() => import("./components/SuperAdminLogin"))
const SuperAdminSideBar = lazy(() => import("./components/SideBarSuperAdmin"))
const AdminList = lazy(() => import("./components/SuperAdmin/AdminList"))
const SuperAdminDashboard = lazy(() => import("./components/SuperAdmin/SuperAdminDashboard"))
const TemplateListOfficialwhatsapp = lazy(() => import("./components/admin/TemplateListOfficalWhatsapp"))
const EnquiryLink = lazy(() => import("./components/EnquiryForm"))
const ConfirmEnquiryForm = lazy(() => import("./components/ConfirmEnquiryForm"))

// const LeadS=lazy(()=>import('./components/admin/Lead'));

/*const Index =lazy(() =>import ('./components/Index'));
const Aboutus =lazy(() =>import ('./components/Aboutus'));
const TermsCondition =lazy(() =>import ('./components/TermsCondition'));
const PrivacyPolicy =lazy(() =>import ('./components/PrivacyPolicy'));
const Faqs =lazy(() =>import ('./components/Faqs'));
const HowItWorks =lazy(() =>import ('./components/HowItWorks'));
const AboutPartners =lazy(() =>import ('./components/AboutPartners'));
const aboutTeam =lazy(() =>import ('./components/aboutTeam'));
const Contact =lazy(() =>import ('./components/Contact'));

const PostInfo =lazy(() =>import ('./components/PostInfoPage'));

const NgoRegProcOne =lazy(() =>import ('./components/ngo/NgoRegProcOne'));
const NgoRegProcTwo =lazy(() =>import ('./components/ngo/NgoRegProcTwo'));
const NgoRegProcThree =lazy(() =>import ('./components/ngo/NgoRegProcThree'));
const NgoRegProcFour =lazy(() =>import ('./components/ngo/NgoRegProcFour'));
const NgoRegProcThankYou =lazy(() =>import ('./components/ngo/NgoRegProcThankYou'));
const NgoRegProcOneUpdate =lazy(() =>import ('./components/ngo/NgoRegProcOneUpdate'));
const NgoRegProcTwoUpdate =lazy(() =>import ('./components/ngo/NgoRegProcTwoUpdate'));
const NgoRegProcThreeUpdate =lazy(() =>import ('./components/ngo/NgoRegProcThreeUpdate'));
const NgoRegProcFourUpdate =lazy(() =>import ('./components/ngo/NgoRegProcFourUpdate'));

const NgoRegProgThankYou =lazy(() =>import ('./components/ngo/NgoRegProgressThankYou'));

const NgoHome =lazy(() =>import ('./components/ngo/NgoHome'));

const UserHome =lazy(() =>import ('./components/user/UserHome'));
const UserProfile =lazy(() =>import ('./components/user/UserProfile'));
const UserUserProfile =lazy(() =>import ('./components/user/UserUserProfile'));

const UserNgoList =lazy(() =>import ('./components/user/UserNgoList'));
//const UserList =lazy(() =>import ('./components/user/UserList'));
const UserProfile1 =lazy(() =>import ('./components/user/UserProfile1'));
//const UserVolunteerList =lazy(() =>import ('./components/user/UserVolunteerList'));
const UserNgoReview =lazy(() =>import ('./components/user/UserNgoReview'));
const UserNgoInfo =lazy(() =>import ('./components/user/UserNgoInfo'));
const NgoReviewList =lazy(() =>import ('./components/ngo/NgoReviewList'));
const NgoAllValunteer =lazy(() =>import ('./components/ngo/NgoAllValunteer'));
//const NgoInterestedValunteer =lazy(() =>import ('./components/ngo/NgoAllInterestedValunteer'));
//const NgoValunteerListing =lazy(() =>import ('./components/ngo/NgoAllValunteerListing'));
const NgoProfile =lazy(() =>import ('./components/ngo/NgoProfilePage'));
const NgoAboutProfile =lazy(() =>import ('./components/ngo/NgoAboutProfile'));
const NgoOrganization =lazy(() =>import ('./components/ngo/NgoOrganization'));
const NgoDonation =lazy(() =>import ('./components/ngo/NgoDonation'));
//const NgoDoGoodListing =lazy(() =>import ('./components/ngo/NgoDoGoodListing'));
const NgoNotification =lazy(() =>import ('./components/ngo/NgoNotification'));
const NgoAboutProfile1 =lazy(() =>import ('./components/ngo/NgoAboutProfile1'));

const UserDonate =lazy(() =>import ('./components/user/UserDonate'));
const UserValunteer =lazy(() =>import ('./components/user/UserValunteer'));
const UserAboutProfile =lazy(() =>import ('./components/user/UserAboutProfile'));

const UserMyCampaign =lazy(() =>import ('./components/user/UserMyCampaign'));
const UserMyDonations =lazy(() =>import ('./components/user/UserMyDonations'));

const Volunteer =lazy(() =>import ('./components/Valunteer'));
const Donate =lazy(() =>import ('./components/Donate'));
const NgoList =lazy(() =>import ('./components/NgoList'));

const UserNgoProfile =lazy(() =>import ('./components/user/NgoProfilePage'));
const UserNgoAboutProfile =lazy(() =>import ('./components/user/NgoAboutProfile'));

const UserNotification =lazy(() =>import ('./components/user/UserNotification'));*/

toast.configure();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTokenFound: false,
      isloading: false,
      isTokenForsuperAdmin: false,
      lastinactive_time: new Date(),
      status:"",
      listProductData:[],
      listLeadStatusData:[],
      ListFieldEnggData:[],
      edit_first_name: "",
      edit_last_name: "",
      edit_mobile: "",
      edit_email_id: "",
      edit_lead_source: "",
      edit_product: "",
      edit_lead_status: "",
      edit_description: "",
      edit_state: "",
      edit_district: "",
      edit_field_engg: "",
      edit_department: "",
      edit_user: "",
      edit_date: "",
      edit_time_slot: "",
      edit_message: "",
      external_notification: "",
      dob: "",
      anniversary_date: "",
      edit_date_time_fu: "",
      isfnamespace: false,
      islnamespace: false,
      first_name_error: false,
      last_name_error: false,
      isemailspace: false
    };
  }

  componentDidMount() {
    this.setState({
      firebasetoken:localStorage.getItem("firebase_token")
    })
    this.AdminInfoFn();

    // let tok=localStorage.getItem("token")
    // // // console.log(localStorage.getItem("token"))

    // if (tok&& typeof tok!=="undefined") {
    //   console.log("helooooooooo");


    //   console.log("port in app ",process.env.PORT)
    // }
    
    let token = localStorage.getItem("token")
    //   alert(token)
    if (token && token !== "" && typeof token !== "undefined") {
      let usercode = jwtDecode(token)
      console.log(usercode);
      // this.setState({
      //   lastinactive_time: new Date()
      // })
      // const inactivityTimeout = 2 * 60 * 60 * 1000;
      // // const inactivityTimeout = 120000;
   
      // let timer= setTimeout(() => {
      //   let currentTime=new Date()
      // console.log(this.state.lastinactive_time);
      // console.log(currentTime);
      //   if (currentTime - this.state.lastinactive_time >= inactivityTimeout) {
      //   //  $("#subscriptionExpire").modal("show")
      //   this.LogOut();
      //   }
      // }, inactivityTimeout);

    




      
      console.log(moment(usercode.iat).format("YYYY-MM-DD HH:mm:SS"));
      console.log(moment(usercode.exp).format("YYYY-MM-DD HH:mm:SS"));
      console.log(moment().format("YYYY-MM-DD HH:mm:SS"));
      var expdate = moment(usercode.exp).format("YYYY-MM-DD HH:mm:SS")
      
      // var date = new Date();
      // var day = date.getDate();
      // var month = date.getMonth() + 1;
      // var year = date.getFullYear();
      //moment(this.state.start_date).add(1, 'day').format("YYYY-MM-DD") 
      var date=new Date(usercode.exp)
     // alert(date)
      var minute=date.setMinutes(date.getMinutes()-2); 
      console.log(moment(expdate).add(2, "minutes").format("YYYY-MM-DD HH:mm:ss"))
      console.log(moment(minute).format("YYYY-MM-DD HH:mm:SS"))
     setInterval(()=>{
      if(moment(minute).format("YYYY-MM-DD HH:mm:SS")==moment().format("YYYY-MM-DD HH:mm:SS")){
        $("#subscriptionExpire").modal("show")
      }
     },1000)
     
    //  var currdate = year + "-" + month + "-" + day;
      //  var currdate = moment().format("hh:mm:ss")
      console.log(expdate <= moment().format("YYYY-MM-DD HH:mm:ss"));
      if (expdate <= moment().format("YYYY-MM-DD HH:mm:ss")) {
     //   console.log("hello2");
     this.LogOut();
       // $("#subscriptionExpire").modal("show")
      }


  
      setInterval(() => {
        console.log("Firebase Notification");
        onMessageListener()
       
        .then((payload) => {
          debugger
          console.log(payload);
          
         // e.preventDefault();
         
          var dateString = moment().format("DD-MM-YYYY hh:mm A");
          console.log('DateString - - ', dateString)
          const [payloadData, _leadPhoneNo] = [payload?.data?.body?.slice(0, 31), payload?.data?.body.slice(33, 45)];
          console.log(payloadData, _leadPhoneNo)
        
          toast.dark(
            <div className="notification-toast-wrap" onClick={() => {
                                                    // this.InfoLeadFun(payload.data.lead_id) 
                                               //      history.push('/admin/Leadlist');
                                            //  window.location.href="/admin/Leadlist"
                                               // this.props.history.push('/admin/Leadlist');
                                                     }}>
              <div className="nt-time">
                <span>{dateString}</span>
              </div>
              <div className="nt-icon">
                <div className="nti-inner">
                  <NotificationsActiveIcon />
                </div>
              </div>
              <div className="nt-title">{payload?.data?.title}</div>
              <div className="nt-body">{payloadData + ' ' + payload?.data?.name}</div>
              <div className="nt-body">{`+${_leadPhoneNo?.slice(0, 2)}  ${_leadPhoneNo?.slice(2, 13)}`}</div>
              
            </div>,
            {
              position: "top-right",
              autoClose: 7000,
              hideProgressBar: false,
              closeonClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
         
          // console.log("payload", payload);
          // Refresh page for rendering
          // setTimeout(() => {
          //   window.location.reload(false);
          // }, 3500);
          // this.ListLeadFun();
          // this.FillerLeadCountFn();
          fetchToken(false);
        })
        .catch((err) => console.log("failed: ", err));       
        
       }, 1000);





      
      
      this.setState({
        isTokenFound: true
      })
    //   console.log(expdate <= moment().format("YYYY-MM-DD HH:MM:SS"));
    //   if (expdate < moment().format("YYYY-MM-DD HH:MM:SS")) {
    //  //   console.log("hello2");
    //  this.LogOut();
    //    // $("#subscriptionExpire").modal("show")
    //   }
      
    //   this.setState({
    //     isTokenFound: true
    //   })



    }
    let token1 = localStorage.getItem("token1")
    //alert(localStorage.getItem("token1"))
    if (token1 && token1 !== "" && typeof token1 !== "undefined") {
      this.setState({
        isTokenForsuperAdmin: true
      })
    }
  //  alert(this.state.isTokenFound);
    fetchToken(this.state.isTokenFound);



    //fetchToken(this.state.isTokenForsuperAdmin);
    // onMessageListener()
    //   .then((payload) => {
    //     var dateString = moment
    //       .unix(payload.data["google.c.a.ts"])
    //       .format("hh:mm A");
    //     toast.dark(
    //       <div className="notification-toast-wrap">
    //         <div className="nt-time">
    //           <span>{dateString}</span>
    //         </div>
    //         <div className="nt-icon">
    //           <div className="nti-inner">
    //             <NotificationsActiveIcon />
    //           </div>
    //         </div>
    //         <div className="nt-title">{payload.notification.title}</div>
    //         <div className="nt-body">{payload.notification.body}</div>
    //       </div>,
    //       {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeonClick: false,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //       }
    //     );
    //     console.log("payload", payload);
    //   })
    //   .catch((err) => console.log("failed: ", err));
  }
  //constructor(props) {
  //super(props);

  /*this.state = {
      currentUser: undefined,
    };*/

  /*history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });*/
  //}

  //componentDidMount() {
  //const user = this.props.user;

  /*if (user) {
      this.setState({
        currentUser: user,
      });
    }*/
  //}
  // LogOut = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("usersss");
  //   window.location.href = "/"
  // }

  AdminInfoFn = () => {
    const { dispatch, history } = this.props;
    if (localStorage.getItem("usersss")) {
      let username = JSON.parse(localStorage.getItem("usersss")).username

      dispatch(AdminInfo(username))
        .then((response) => {
          console.log("user");
          console.log(response.data);
          this.setState({
            //email_id_to_send_mail:response.data[0].email_id_to_send_mail,
            official_whatsapp: response.data[0].official_whatsapp,
            status:response.data[0].status,
            username:response.data[0].username,
            // logo:response.data[0].logo,
            // isFacebookApproved:response.data[0].isFacebookApproved,
            // isEmailApproved:response.data[0].isEmailApproved,
            // isIRVApproved:response.data[0].isIRVApproved,
            // isEnquiryApproved:response.data[0].isEnquiryApproved,
            // isIndiamartApproved:response.data[0].isIndiamartApproved,
            // isWhatsappApproved:response.data[0].isWhatsappApproved,
            // startDate:response.data[0].startDate,
            // endDate:response.data[0].endDate,
            // subscription_type:response.data[0].subscription_type,
            // enquirylink:"https://crm.leadsparrows.com/enquiry/"+response.data[0].name+"/"+token+"/"+response.data[0].official_whatsapp+"/"+response.data[0].logo
            // verifyToken: response.data,
          },()=>{if (this.state.status!==1) {
            console.log("hello1");
            $("#DeactivedAdmin").modal("show")
          }});
          // this.TableDataUpdate();
        })
        .catch(() => {
          this.setState({

          });
        });
      // console.log("indiamart_key"+(JSON.parse(localStorage.getItem("usersss"))).indiamart_key);
    }
  };
  mobilespitfn = (val) => {
    let mob = val == null ? "" : val.toString().slice(2, 12);
    // alert(mob);
    this.setState({
      mobile: mob
    });
  };
  onChangeEditFirstName = (e) => {
    if (e.target.value.length > 25) {
      this.setState({
        first_name_error: true
      })
    }
    else {
      this.setState({
        edit_first_name: e.target.value,
        first_name_error: false
      });
    }

    //   var letters = /^[A-Za-z]+$/;
    //  if(e.target.value.match(letters))
    //    {

    //     this.setState({
    //       edit_first_name: e.target.value,
    //     });

    //    }

    //  else
    //    {
    //    alert("message");
    //    return false;
    //    }
  };

  onChangeEditLastName = (e) => {
    if (e.target.value.length > 25) {
      this.setState({
        //edit_last_name: e.target.value,
        last_name_error: true
      })
    }
    else {
      this.setState({
        edit_last_name: e.target.value,
        last_name_error: false
      });
    }



  };

  onChangeEditMobile = (e) => {
    this.setState({
      edit_mobile: e.target.value,
    });
  };

  onChangeEditEmail = (e) => {
    // if(e.target.value.replace(/\s/g,"")<=0){
    //  console.log("space not allwed")
    // }
    // else{
    this.setState({
      edit_email_id: e.target.value,
    });
    // }

  };

  onChangeEditLeadSource = (e) => {
    this.setState({
      edit_lead_source: e.target.value,
    });
  };

  onChangeEditProduct = (e) => {
    this.setState({
      edit_product: e.target.value,
    });
  };

  onChangeEditLeadStatus = (e) => {
    this.setState({
      edit_lead_status: e.target.value,
    });
  };

  onChangeEditDescription = (e) => {
    this.setState({
      edit_description: e.target.value,
    });
  };

  

  onChangeEditFieldEngg = (e) => {
    this.setState(
      {
        edit_field_engg: e.target.value,
      },
      console.log("edit_field_engg", e.target.value)
    );
  };



  onChangeEditUser = (e) => {
    this.setState({
      edit_user: e.target.value,
    });

  };

  
  

  onChangeEditMessage = (e) => {
    this.setState({
      edit_message: e.target.value,
    });
  };

  onChangeEditDateTime = (e) => {
    this.setState({
      edit_date_time_fu: e.target.value,
    });
  };

  onChangeEditDoB = (e) => {
    this.setState({
      dob: e.target.value,
    })
  }
  onChangeEditAnniversaryDate = (e) => {
    this.setState({
      anniversary_date: e.target.value,
    })
  }






  ListProductFun = () => {
    const { dispatch, history } = this.props;
    dispatch(ProductList("","",""))
      .then((response) => {
        this.setState({
          listProductData: response.data,
          listforprodsearch: response.data,
        });
        // this.TableDataUpdate();
      // alert(JSON.stringify(this.state.listProductData))
      })
      .catch(() => {
        this.setState({
          listProductData: [],
        });
      });
  };

 
  ListFieldEnggFun = () => {
    const { dispatch, history } = this.props;
    dispatch(FieldEnggList("","",""))
      .then((response) => {
        this.setState({
          ListFieldEnggData: response.data,

          listforengineersearch: response.data,
        });
        // this.TableDataUpdate();
      })
      .catch(() => {
        this.setState({
          ListFieldEnggData: [],
        });
      });
  };
  ListLeadStatusFun = () => {
    const { dispatch, history } = this.props;
    dispatch(LeadStatusList())
      .then((response) => {
        this.setState({
          listLeadStatusData: response.data,

          listforstatussearch: response.data,
        });
        // this.TableDataUpdate();
        console.log("listLeadStatusData", response.data)
      })
      .catch(() => {
        this.setState({
          listLeadStatusData: [],
        });
      });
  };


  InfoLeadFun = (id) => {
    debugger
    const { dispatch, history } = this.props;
    dispatch(LeadEdit(id))
      .then((response) => {
        // console.log("response----info dev", response);
        if (
          response.data &&
          typeof response.data !== "undefined" &&
          response.data.length > 0
        ) {
          // $("#EditLead").modal("show");
       
          // this.ListFieldEnggByIdfn(
          //   response.data[0].state,
          //   response.data[0].district
          // );
          this.ListFieldEnggFun();
          this.ListLeadStatusFun();
          this.ListProductFun();
          // this.SlotListByDateFn(response.data[0].fg_id, response.data[0].date);
          this.mobilespitfn(response.data[0].mobile);
          // console.log(
          //   "ListFieldEnggByIdfn========" + response.data[0].state,
          //   response.data[0].district
          // );
          this.setState({
            id: response.data[0].id,
            edit_first_name: response.data[0].first_name,
            edit_last_name: response.data[0].last_name!== null ?response.data[0].last_name:"",
            //edit_mobile: response.data[0].mobile,
            edit_mobile: this.state.mobile,
            edit_email_id: response.data[0].email_id !== null ?response.data[0].email_id:"",
            edit_lead_source: response.data[0].lead_source !== null ? response.data[0].lead_source.toLowerCase() : "",
            ivr_lead_source: response.data[0].lead_source !== null ? response.data[0].lead_source.toLowerCase() : "",
            edit_product: response.data[0].product,
            edit_lead_status: response.data[0].lead_status,
            edit_description: response.data[0].description,
            edit_state: response.data[0].state,
            edit_district: response.data[0].district,
            edit_field_engg: response.data[0].fg_id,
            edit_department: response.data[0].department,
            edit_user: response.data[0].user,
            edit_date: moment(response.data[0].date).format("YYYY-MM-DD"),
            edit_date_time_staus: moment(response.data[0].date).format("YYYY-MM-DD HH:MM:SS"),
            dob: moment(response.data[0].dob).format("YYYY-MM-DD"),
            anniversary_date: moment(response.data[0].anniversary_date).format("YYYY-MM-DD"),

            edit_time_slot: response.data[0].time_slot,
            edit_message: response.data[0].message,
            external_notification: response.data[0].external_notification,

            edit_date_time_fu: moment(response.data[0].date_time_fu).format("YYYY-MM-DD HH:MM:SS"),
          });

        console.log("filed engineer_date",this.state.edit_mobile);
          console.log("filed engineer_date", response.data[0].last_name);
          $("#EditLead").modal("show");
        }
      })
      .catch((error) => {
        alert(error.message);
        this.setState({

          loading: false,
        });
      });
  };

 
  handleUpdateSubmit = (e) => {
    debugger
    e.preventDefault();

    this.setState({
      loading: true,
    });
    if (this.state.edit_first_name.replace(/\s/g, "").length <= 0) {
      this.setState({
        isfnamespace: true,
      });

    }
    try{
      console.log(this.state.edit_last_name );
      console.log(this.state.edit_last_name !== 'undefined' );
      console.log(this.state.edit_last_name !== null );
      console.log(this.state.edit_last_name !== "" );
      console.log(this.state.edit_last_name.replace(/\s/g, "").length);
    if (this.state.edit_last_name !== 'undefined' && this.state.edit_last_name !== null && this.state.edit_last_name !== "") {
      if (this.state.edit_last_name.replace(/\s/g, "").length <= 0) {
        this.setState({
          islnamespace: true,
        });

      }
    }
  }catch(ex){
    alert(ex)
  }
    //   try{
    //   if(this.state.edit_email_id.replace(/\s/g,"").length<=0 ){
    //     this.setState({
    //       isemailspace:true,
    //     });
    //   }
    // }catch(e){
    //   alert(this.state.edit_email_id)
    //   alert(e)
    // }
    const { dispatch, history } = this.props;
    // this.Addform.validateAll();


    //alert(this.state.edit_date)
    if (this.checkBtn.context._errors.length === 0) {
      if (this.state.isfnamespace == false &&
        this.state.islnamespace == false
        //  this.state.isemailspace==true
      ) {
        dispatch(
          LeadUpdate(
            this.state.id,
            this.state.edit_first_name==null?"":this.state.edit_first_name,
            this.state.edit_last_name==null?"":this.state.edit_last_name,
             this.state.edit_mobile==null?"":"91" + this.state.edit_mobile,
            this.state.edit_email_id == null?"":this.state.edit_email_id,
            this.state.edit_lead_source==null?"":this.state.edit_lead_source,
            this.state.edit_product==null?"":this.state.edit_product,
            this.state.edit_lead_status==null?"":this.state.edit_lead_status,
            this.state.edit_description==null?"":this.state.edit_description,
            // this.state.edit_state,
            // this.state.edit_district,
            this.state.edit_field_engg==null?"":this.state.edit_field_engg,
            this.state.edit_department==null?"":this.state.edit_department,
            this.state.edit_user==null?"":this.state.edit_user,
            //  this.state.edit_date == 'Invalid date' ? '' : this.state.edit_date,
            //  this.state.edit_time_slot,
            this.state.edit_message==null?"":this.state.edit_message,
            this.state.external_notification==null?"":this.state.external_notification,
            this.state.dob == 'Invalid date' ? '' : this.state.dob,
            this.state.anniversary_date == 'Invalid date' ? '' : this.state.anniversary_date,
            this.state.edit_date_time_fu == 'Invalid date' ? '' : this.state.edit_date_time_fu
          )
        )
          .then((response) => {
            if (
              response.success ||
              response.success === "true" ||
              response.success === true
            ) {
              toast.success(response.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeonClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // this.ListLeadFun();
        
              this.setState({
                edit_first_name: "",
                edit_last_name: "",
                edit_mobile: "",
                edit_email_id: "",
                edit_lead_source: "",
                edit_product: "",
                edit_lead_status: "",
                edit_description: "",
                edit_state: "",
                edit_district: "",
                edit_field_engg: "",
                edit_department: "",
                edit_user: "",
                edit_date: "",
                edit_time_slot: "",
                edit_message: "",
                external_notification: "",
                dob: "",
                anniversary_date: "",
                edit_date_time_fu: "",
                isfnamespace: false,
                islnamespace: false,
                first_name_error: false,
                last_name_error: false,
                isemailspace: false
              });
              $("#EditLead").modal("hide");
              window.location.reload();
            } else {
              toast.error(response.message, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeonClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((error) => {
            alert(error)
            this.setState({
              loading: false,
            });
            toast.error("something went wrong..!!", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });

      }
    } else {
      this.setState({
        loading: false,
      });
      toast.error("something went wrong..!!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  LogOut = () => {
  
   const { dispatch, history } = this.props;
    if (localStorage.getItem("usersss")) {
      let username = JSON.parse(localStorage.getItem("usersss")).username
      let firebasetoken = localStorage.getItem("firebase_token")
      console.log(username);
      dispatch(AdminLogout(username,firebasetoken))
      .then((response) => {
       

        if (
          response.success ||
          response.success === "true" ||
          response.success === true
        ) {
          // localStorage.setItem("firebase_token",currentToken)
          window.location.href = "/"
          toast.success(response.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          // this.ListTemplateFun(
          //   this.state.template_msg,
          //   Number(this.state.template_id)
          // );

      
        } else {
          this.setState({
            loading: false
          })
          
          toast.error(response.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeonClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        localStorage.removeItem("token");
        localStorage.removeItem("usersss");
       localStorage.removeItem("firebase_token");
      window.location.href = "/"
      })
      .catch((error) => {
        console.log("error++", error)
        this.setState({
          loading: false,
        });

        toast.error("something went wrong..!!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
      // console.log("indiamart_key"+(JSON.parse(localStorage.getItem("usersss"))).indiamart_key);
    }
  };


  render() {
    //const { currentUser } = this.state;

    return (
      <React.Fragment>
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Switch>
            //without login pages
                  
              <Route exact path="/enquiry/:user/:token/:whatsapp/:logo" component={EnquiryLink} />
              <Route exact path="/ConfirmAppointment/:id/:user" component={ConfirmEnquiryForm} />

              <Route exact path="/superadminlogin" component={SuperAdminLogin} />
              {/* {(() => {
              if (!this.state.isTokenForsuperAdmin) {
                return (
                  <>
          
             <Route exact path="/superadmin/sidebar" component={SuperAdminLogin}/>
            <Route exact path="/superadmin/admin/list" component={SuperAdminLogin}/>
            <Route exact path="/superadmin/Dahboard" component={SuperAdminLogin}/>
            </>)}
            })()} */}
              {/* {(() => {
              if (this.state.isTokenForsuperAdmin) {
                return (
                  <> */}

              <Route exact path="/superadmin/sidebar" component={SuperAdminSideBar} />
              <Route exact path="/superadmin/admin/list" component={AdminList} />
              <Route exact path="/superadmin/Dashboard" component={SuperAdminDashboard} />
              {/* </>)}
            })()}  */}

              {/* if not logged in then redirect to login page  */}
              {(() => {
                if (!this.state.isTokenFound) {

                  return (
                    <>
                      <Route exact path="/" component={Login} />
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/admin/dashboard" component={Login} />
                      <Route exact path="/admin/user/list" component={Login} />

                      <Route exact path="/admin/product/list" component={Login} />
                      <Route
                        exact
                        path="/admin/department/list"
                        component={Login}
                      />
                      <Route exact path="/admin/Leadlist" component={Login} />
                      <Route exact path="/admin/staff/list" component={Login} />
                      <Route exact path="/admin/slot/list" component={Login} />
                      <Route exact path="/admin/template/list" component={Login} />
                      <Route exact path="/admin/role/list" component={Login} />
                      <Route exact path="/admin/campaign/list" component={Login} />
                      <Route exact path="/admin/drive/list" component={Login} />
                      <Route exact path="/admin/report/list" component={Login} />
                      <Route exact path="/admin/appointment/report/list" component={Login} />
                      <Route exact path="/admin/DTMF/list" component={Login} />
                      <Route exact path="/admin/notification/list" component={Login} />
                    </>
                  )
                }
              })()}
              {/*------------ ----------------------------------------------------------------------- */}

              {(() => {
                console.log("this.state.isTokenFound" + this.state.isTokenFound);
                if (this.state.isTokenFound) {
                  return (
                    <>
                    
                      <Route exact path="/" component={Dashboard} />
                      <Route exact path="/login" component={Dashboard} />
                      <Route exact path="/admin/dashboard" component={Dashboard} />
                      <Route exact path="/admin/user/list" component={UserList} />
                      {/* <Route exact path="/admin/lead/list" component={LeadList} /> */}
                      <Route exact path="/admin/product/list" component={ProductListcomp} />
                      <Route
                        exact
                        path="/admin/department/list"
                        component={DepartmentList}
                      />
                      <Route exact path="/admin/Leadlist" component={Lead} />
                      <Route exact path="/admin/staff/list" component={FieldEngg} />
                      {JSON.parse(localStorage.getItem("usersss")) &&
                        <>
                          {(JSON.parse(localStorage.getItem("usersss")).ApiVersion == "v2") &&
                            <>
                              <Route exact path="/admin/slot/list/v2" component={SlotListV1} />
                              <Route exact path="/admin/staff/list/v2" component={StaffListV1} />
                            </>
                          }
                        </>}
                      <Route exact path="/admin/slot/list" component={SlotList} />


                      {this.state.official_whatsapp == 1 &&

                        <Route exact path="/admin/template/list" component={TemplateListOfficialwhatsapp} />
                      }
                      {(this.state.official_whatsapp == 0 || this.state.official_whatsapp == 2 )&&
                        <Route exact path="/admin/template/list" component={TemplateList} />
                      }






                      <Route exact path="/admin/role/list" component={RoleList} />
                      <Route exact path="/admin/campaign/list" component={CampaignList} />
                      <Route exact path="/admin/drive/list" component={DriveList} />
                      <Route exact path="/admin/report/list" component={ReportList} />
                      <Route exact path="/admin/appointment/report/list" component={ReportListAppointment} />
                      <Route exact path="/admin/ProductRevenue/Report/list" component={ProductRevenueReport} />
                      <Route exact path="/admin/DTMF/list" component={DTMFList} />
                      <Route exact path="/admin/notification/list" component={Notifications} />
                    </>
                  )
                }
              })()}


              {/* <Route exact path="/admin/lead" component={LeadS}/> */}
              {/*<Route exact path="/volunteer" component={Volunteer} />
            <Route exact path="/donate" component={Donate} />
            <Route exact path="/ngolist" component={NgoList} />

            <Route exact path="/post/info/:post_id" component={PostInfo} />

            //static page
            <Route exact path="/aboutus" component={Aboutus} />
            <Route exact path="/termscondition" component={TermsCondition} />
            <Route exact path="/privacypolicy" component={PrivacyPolicy} />
            <Route exact path="/faqs" component={Faqs} />
            <Route exact path="/howitworks" component={HowItWorks} />
            <Route exact path="/aboutpartners" component={AboutPartners} />
            <Route exact path="/aboutteam" component={aboutTeam} />
            <Route exact path="/contact" component={Contact} />

            //ngo register
            <Route exact path="/ngo/registration/one" component={NgoRegProcOne} />
            <Route exact path="/ngo/registration/two" component={NgoRegProcTwo} />
            <Route exact path="/ngo/registration/three" component={NgoRegProcThree} />
            <Route exact path="/ngo/registration/four" component={NgoRegProcFour} />
            <Route exact path="/ngo/registration/thankyou" component={NgoRegProcThankYou} />
			
			      <Route exact path="/ngo/registration/updateone/:ngo_id" component={NgoRegProcOneUpdate} />
            <Route exact path="/ngo/registration/updatetwo/:ngo_id" component={NgoRegProcTwoUpdate} />
            <Route exact path="/ngo/registration/updatethree/:ngo_id" component={NgoRegProcThreeUpdate} />
            <Route exact path="/ngo/registration/updatefour/:ngo_id" component={NgoRegProcFourUpdate} />
            <Route exact path="/ngo/progress" component={NgoRegProgThankYou} />
            
            //ngo
            <Route exact path="/ngo/home" component={NgoHome} />
            <Route exact path="/ngo/reviewlist" component={NgoReviewList} />
            <Route exact path="/ngo/ngoallvalunteer" component={NgoAllValunteer} />
            {/*<Route exact path="/ngo/ngointerestedvalunteer" component={NgoInterestedValunteer} />
            <Route exact path="/ngo/ngovalunteerlisting" component={NgoValunteerListing} />*/}
              {/*<Route exact path="/ngo/profile" component={NgoProfile} />
            <Route exact path="/ngo/about_profile" component={NgoAboutProfile} />
            <Route exact path="/ngo/organisational_details" component={NgoOrganization} />
           
            <Route exact path="/ngo/ngoAboutProfile1" component={ NgoAboutProfile1} /> 
            <Route exact path="/ngo/donations" component={NgoDonation} />
            {/*<Route exact path="/ngo/dogoodlisting" component={NgoDoGoodListing} />*/}
              {/*<Route exact path="/ngo/notification" component={NgoNotification} />

            /*<Route exact path="/ngo/post/info/:post_id" component={PostInfo} />*/}
              {/*<Route exact path="/user/home" component={UserHome} />
            <Route exact path="/user/profile" component={UserProfile} />
            <Route exact path="/user/info/:user_id" component={UserUserProfile} />
            <Route exact path="/user/ngo/list" component={UserNgoList} />
            <Route exact path="/user/ngo/info/:ngo_id" component={UserNgoInfo} />
            <Route exact path="/user/user/list" component={UserList} />
            {/*<Route exact path="/user/donate/list" component={UserDonateList} />
            <Route exact path="/user/volunteer/list" component={UserVolunteerList} />*/}
              {/*<Route exact path="/user/ngo/review/:ngo_id" component={UserNgoReview} />
            <Route exact path="/user/donate" component={UserDonate} />
            <Route exact path="/user/volunteer" component={UserValunteer} />
            <Route exact path="/user/about_profile" component={UserAboutProfile} />
            <Route exact path="/user/user_my_campaign" component={UserMyCampaign} />
            <Route exact path="/user/user_my_donations" component={UserMyDonations} />
            <Route exact path="/user/ngo/profile/:ngo_id" component={UserNgoProfile} />
            <Route exact path="/user/ngo/about_profile/:ngo_id" component={UserNgoAboutProfile} />
            <Route exact path="/user/notification" component={UserNotification} />
            <Route exact path="/user/userprofile1" component={UserProfile1} />*/}
              {/*<Route exact path="/user/post/info/:post_id" component={PostInfo} />*/}
            </Switch>
          </Suspense>
        </BrowserRouter>
        <div
          className="modal fade"
          id="subscriptionExpire"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm ">
            <div className="modal-content pt-0 pb-2 px-3">
              {/* <div className="modal-header border-0 pb-0">
                    <button type="button" className="close" data-dismiss="modal"><img src={closeicon} width="20px" height="20px"/></button>
                </div> */}
              <div className="container">
                {/* <Form
                onSubmit={this.handlengoPostSubmit}
                ref={(c) => {
                  this.form = c;
                }}> */}

                <div className="row ">
                  <div className="col-md-12 col-12 pl-md-0 position-relative">
                    <div className="row">


                      <div className=" pb-2 pt-3">
                        <h3 className="text-center">
                        Hi! please save your all work, as within 2 minutes your session will expire. 
                        </h3>
                      </div>

                      <div className="col-12  pb-2 pt-3">

                        <div className="display-center">
                          <button
                            className="btn btn-primary ok-btn"
                            data-dismiss="modal"
                         //   onClick={this.LogOut}
                          >
                            Ok
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="DeactivedAdmin"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm ">
            <div className="modal-content pt-0 pb-2 px-3">
              {/* <div className="modal-header border-0 pb-0">
                    <button type="button" className="close" data-dismiss="modal"><img src={closeicon} width="20px" height="20px"/></button>
                </div> */}
              <div className="container">
                {/* <Form
                onSubmit={this.handlengoPostSubmit}
                ref={(c) => {
                  this.form = c;
                }}> */}

                <div className="row ">
                  <div className="">
                    <div className="row">


                      <div className="col-12  pb-2 pt-3">
                        <h3 className="display-center">
                          Your account has been deactivated.
                        </h3>
                      </div>

                      <div className="col-12  pb-2 pt-3">

                        <div className="display-center">
                          <button
                            className="btn btn-primary ok-btn"
                            //data-dismiss="modal"
                            onClick={this.LogOut}
                          >
                            Ok
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="Logoutin2hr"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm ">
            <div className="modal-content pt-0 pb-2 px-3">
              {/* <div className="modal-header border-0 pb-0">
                    <button type="button" className="close" data-dismiss="modal"><img src={closeicon} width="20px" height="20px"/></button>
                </div> */}
              <div className="container">
                {/* <Form
                onSubmit={this.handlengoPostSubmit}
                ref={(c) => {
                  this.form = c;
                }}> */}

                <div className="row ">
                  <div className="">
                    <div className="row">


                      <div className="col-12  pb-2 pt-3">
                        <h3 className="display-center">
                          Your subscription has been expired.
                        </h3>
                      </div>

                      <div className="col-12  pb-2 pt-3">

                        <div className="display-center">
                          <button
                            className="btn btn-primary ok-btn"
                            //data-dismiss="modal"
                            onClick={this.LogOut}
                          >
                            Ok
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="EditLead"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content pt-4 pb-4 px-4">
              <div className="modal-header border-0 pb-0 pt-0 pr-0">
                <button type="button" className="close" data-dismiss="modal" onClick={this.oncloseeditlead}>
                  <img src={closeicon} width="20px" height="20px" />
                </button>
              </div>
              <div className="container">
                <Form
                  onSubmit={this.handleUpdateSubmit}
                  ref={(c) => {
                    this.form = c;
                  }}
                >
                  <h3 className="size18  mt-0">
                    <img src={Profile} width="25px" height="25x"></img>{" "}
                    <span className="title"> Edit Lead Details</span>{" "}
                  </h3>
                  <div className="row  ">
                    <div className="col-md-12 col-12 pl-md-0 position-relative">
                      <div className="row">
                        <div className="col-6  pb-2">
                          <label className="ModelInputlabel">First Name<span style={{ color: "red" }}> *</span></label>
                          <input
                            type="text"
                            pattern="[a-zA-Z ]+"
                            className="modalInputBox form-control h-small shadow1"
                            name="edit_first_name"
                            id="edit_first_name"
                            onChange={this.onChangeEditFirstName}
                            value={this.state.edit_first_name}
                            //   oninvalid="setCustomValidity('Please enter on alphabets only. ')"
                            title="Please enter on alphabets only."
                            required
                            maxLength={26}
                          />
                          {this.state.isfnamespace == true &&
                            <>
                              <span style={{ color: "red", fontSize: "13px" }}>Space is not allowed</span>

                            </>
                          }
                          {this.state.first_name_error == true &&
                            <label style={{ color: "red", fontSize: "12px" }}>
                              Name should  contain only 25 chararater.

                            </label>
                          }

                        </div>
                        <div className="col-6  pb-2">
                          <label className="ModelInputlabel"> Last Name </label>
                          <input
                            type="text"
                            className="modalInputBox form-control h-small shadow1"
                            name="edit_last_name"
                            id="edit_last_name"
                            onChange={this.onChangeEditLastName}
                            value={this.state.edit_last_name}
                            pattern="[a-zA-Z ]+"
                            title="Please enter on alphabets only."
                            maxLength={26}
                          />
                          {this.state.islnamespace == true &&
                            <>
                              <span style={{ color: "red", fontSize: "13px" }}>Space is not allowed</span>

                            </>
                          }
                          {this.state.last_name_error == true &&
                            <label style={{ color: "red", fontSize: "12px" }}>
                              Name should  contain only 25 chararater.

                            </label>
                          }
                        </div>
                        <div className="col-6  pb-2">
                          <label className="ModelInputlabel">Mobile <span style={{ color: "red" }}> *</span></label>
                          <input
                            type="tel"
                            className="modalInputBox form-control h-small shadow1"
                            name="edit_mobile"
                            id="edit_mobile"
                            onChange={this.onChangeEditMobile}
                            value={this.state.edit_mobile}
                            required
                            pattern="[0-9]{10}"
                            title="Phone No. should contain 10 digit."
                            maxLength={10}
                            style={{backgroundColor:this.state.ivr_lead_source=="ivr" ? "#E9ECEF":""}}
                            disabled={this.state.ivr_lead_source=="ivr" ? true : false}
                          />
                        </div>
                        <div className="col-6  pb-2">
                          <label className="ModelInputlabel">Email Id</label>
                          <input
                            type="email"
                            className="modalInputBox form-control h-small shadow1"
                            name="edit_email_id"
                            id="edit_email_id"
                            onChange={this.onChangeEditEmail}
                            value={this.state.edit_email_id}

                          />
                          {this.state.isemailspace == true &&
                            <>
                              <span style={{ color: "red", fontSize: "13px" }}>Space is not allowed</span>

                            </>
                          }
                        </div>

                        <div className="col-6  pb-2 ">
                          <label className='ModelInputlabel'>DoB</label>
                          <input type="Date" className="modalInputBox form-control h-small shadow1" name="dob" id="dob" onChange={this.onChangeEditDoB} value={this.state.dob} /><i class="fa fa-calendar"></i>
                        </div>
                        <div className="col-6  pb-2 ">
                          <label className='ModelInputlabel'>Anniversary Date</label>
                          <input type="Date" className="modalInputBox form-control h-small shadow1" name="anniversary_date" id="anniversary_date" onChange={this.onChangeEditAnniversaryDate} value={this.state.anniversary_date} /><i class="fa fa-calendar"></i>
                        </div>

                        <div className="col-6  pb-2">
                          <label className="ModelInputlabel">
                            Select Lead Source<span style={{ color: "red" }}> *</span>
                          </label>
                          <div className="form-select">
                            <div className="w-100">
                              <select
                                className="modalInputBox form-control h-small shadow1"
                                name="edit_lead_source"
                                id="edit_lead_source"
                                onChange={this.onChangeEditLeadSource}
                                value={this.state.edit_lead_source}
                                required
                              >
                                <option value="">Select Lead Source </option>
                                <option value="call"> Call </option>
                                <option value="whatsapp">WhatsApp </option>
                                <option value="ivr">Ivr</option>
                                <option value="india mart"> India mart </option>
                                <option value="facebook">Facebook </option>
                                <option value="justdial">Justdial </option>
                                <option value="website">Website</option>
                             
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-6  pb-2">
                          <label className="ModelInputlabel">
                            Select Product
                          </label>
                          <div className="form-select">
                            <div className="w-100">
                              <select
                                className="modalInputBox form-control h-small shadow1"
                                name="edit_product"
                                id="edit_product"
                                onChange={this.onChangeEditProduct}
                                value={this.state.edit_product}

                              >
                                <option value="">Select Product </option>
                                {this.state.listProductData.length > 0 &&
                                  this.state.listProductData &&
                                  this.state.listProductData.map(
                                    (item, index) => (
                                      <option key={index} value={item.id}>
                                        {item.product_name}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 pb-2">
                          <label className="ModelInputlabel">Lead Status</label>
                          <div className="form-select">
                            <div className="w-100">
                              <select
                                className="modalInputBox form-control h-small shadow1"
                                name="edit_lead_status"
                                id="edit_lead_status"
                                onChange={(e) =>
                                  this.setState({
                                    edit_lead_status: e.target.value,
                                  })
                                }
                                value={this.state.edit_lead_status}

                              >
                                <option value="">Select Status</option>
                                {this.state.listLeadStatusData.length > 0 &&
                                  this.state.listLeadStatusData &&
                                  this.state.listLeadStatusData.map(
                                    (item, index) => (
                                      <option key={index} value={item.id}>
                                        {item.status_name}
                                      </option>
                                    )
                                  )}
                              </select>
                            </div>
                          </div>
                        </div>

                        {this.state.edit_lead_status == 2 ||
                          this.state.edit_lead_status == 3 ||
                          this.state.edit_lead_status == 4 ||
                          this.state.edit_lead_status == 5 ? (
                          <>
                            <div className="col-6  pb-2">
                              <label className="ModelInputlabel">
                                Subject{" "}
                              </label>
                              <div className="form-select">
                                <div className="w-100">
                                  <select
                                    className="modalInputBox form-control h-small shadow1"
                                    name="Subject"
                                    id="Subject"
                                    defaultValue=""

                                  >
                                    <option value="Call">Call</option>
                                    <option value="Message"> Message </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}


                        {/* {this.state.edit_lead_status == 1 && (
                          <>
                            <div className="col-6  pb-2">
                              <label className="ModelInputlabel">
                                Select State<span style={{color:"red"}}> *</span>
                              </label>
                              <div className="form-select">
                                <div className="w-100">
                                  <select
                                    className="modalInputBox form-control h-small shadow1"
                                    name="edit_state"
                                    id="edit_state"
                                    onChange={this.onChangeEditState}
                                    defaultValue={this.state.edit_state}
                                    required
                                  >
                                    <option value="">Select State </option>
                                    {this.state.listStateData &&
                                      typeof this.state.listStateData !==
                                        "undefined" &&
                                      this.state.listStateData.length > 0 &&
                                      this.state.listStateData.map(
                                        (item, index) => (
                                          <option
                                            key={index}
                                            value={item.state_id}
                                          >
                                            {item.state_name}{" "}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </>
                        )} */}

                        {this.state.edit_lead_status == 2 ||
                          this.state.edit_lead_status == 3 ||
                          this.state.edit_lead_status == 4 ||
                          this.state.edit_lead_status == 5 ? (
                          <>
                            <div className="col-6  pb-2">
                              <label className="ModelInputlabel">
                                Message{" "}<span style={{ color: "red" }}> *</span>
                              </label>

                              <input
                                type="text"
                                className="modalInputBox form-control h-small shadow1"
                                name="Message"
                                id="Message"
                                onChange={this.onChangeEditMessage}
                                value={this.state.edit_message}
                                required
                              />
                            </div>
                          </>
                        ) : null}
                        {this.state.edit_lead_status == 3 ||
                          this.state.edit_lead_status == 4 ||
                          this.state.edit_lead_status == 5 ? (
                          <>
                            <div className="col-6  pb-2">
                              <label className="ModelInputlabel">
                                Select Date & Time<span style={{ color: "red" }}> *</span>
                              </label>
                              <input
                                type="datetime-local"
                                className="modalInputBox form-control h-small"
                                name="edit_date"
                                id="edit_date"
                                onChange={this.onChangeEditDateTime}
                                value={this.state.edit_date_time_fu}
                                required
                                min={moment().format("YYYY-MM-DD hh:mm")}
                              />
                              <i className="fa fa-calendar ava_time-slot"></i>
                            </div>
                          </>
                        ) : null}

                        {this.state.edit_lead_status == 1 && (
                          <>
                            {/* <div className="col-6  pb-2">
                              <label className="ModelInputlabel">
                                Select District<span style={{color:"red"}}> *</span>
                              </label>
                              <div className="form-select">
                                <div className="w-100">
                                  <select
                                    className="modalInputBox form-control h-small shadow1"
                                    name="edit_district"
                                    id="edit_district"
                                    onChange={this.onChangeEditDistrict}
                                    value={this.state.edit_district}
                                  >
                                    <option value="">Select District </option>
                                    {this.state.listDistrictData &&
                                      typeof this.state.listDistrictData !==
                                        "undefined" &&
                                      this.state.listDistrictData.length > 0 &&
                                      this.state.listDistrictData.map(
                                        (item, index) => (
                                          <option
                                            key={index}
                                            value={item.district_id}
                                          >
                                            {item.district_name}{" "}
                                          </option>
                                        )
                                      )}
                                  </select>
                                </div>
                              </div>
                            </div> */}

                            <div className="col-6  pb-2">
                              <label className="ModelInputlabel">
                                Select Staff<span style={{ color: "red" }}> *</span>
                              </label>
                              <div className="form-select">
                                <div className="w-100">
                                  <select
                                    className="modalInputBox form-control h-small shadow1"
                                    name="edit_field_engg"
                                    id="edit_field_engg"
                                    onChange={this.onChangeEditFieldEngg}
                                    value={this.state.edit_field_engg}
                                    required
                                  >
                                    <option value="">Select Staff</option>
                                    {/* {this.state.ListFieldEnggData && typeof this.state.ListFieldEnggData!=="undefined" && this.state.ListFieldEnggData.length>0 && this.state.ListFieldEnggData.map((item)=>(
                                          <>
                                           <option value={item.id}>{item.name}</option>
                                          </>
                                                ))} */}
                                    {this.state.ListFieldEnggData &&
                                      typeof this.state
                                        .ListFieldEnggData !==
                                      "undefined" &&
                                      this.state.ListFieldEnggData.length >
                                      0 &&
                                      this.state.ListFieldEnggData.map(
                                        (item, index) => (
                                          <>
                                            {item.staff_status == 1 &&
                                              <option key={index} value={item.id}>
                                                {item.name}
                                              </option>
                                            }
                                          </>
                                        )
                                      )}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-6  pb-2">
                                <label>Select Department</label>
                                  <div className="form-select">
                                    <div className="w-100">
                                      <select className="form-control h-small shadow1" name="edit_department" id="edit_department" onChange={this.onChangeEditDepartment} value={this.state.edit_department} required>
                                        <option>Select Department</option>
                                        {this.state.listDepartmentData.length > 0 && this.state.listDepartmentData && this.state.listDepartmentData.map((item) => (
                                          <>
                                        <option value={item.id}>{item.department_name}</option>
                                        </>
                                      ))}
                                      </select>
                                    </div>
                                  </div>
                                </div> */}

                            {/* <div className="col-6  pb-2">
                                <label>Select User</label>
                                  <div className="form-select">
                                    <div className="w-100">
                                      <select className="form-control h-small shadow1" name="edit_user" id="edit_user" onChange={this.onChangeEditUser} value={this.state.edit_user} required>
                                      <option>Select User</option>
                                        {this.state.listUserData.length > 0 && this.state.listUserData && this.state.listUserData.map((item) => (
                                          <>
                                        <option value={item.id}>{item.user_name}</option>
                                        </>
                                      ))}
                                      </select>
                                    </div>
                                  </div>
                                </div> */}

                            {/* <div className="col-6  pb-2">
                              <label className="ModelInputlabel">
                                Select Date
                              </label>
                              <input
                                type="date"
                                className="modalInputBox form-control h-small"
                                name="edit_date"
                                id="edit_date"
                                onChange={this.onChangeEditDate}
                                defaultValue={this.state.edit_date}

                                min={moment().format("YYYY-MM-DD")}
                              />
                              <i className="fa fa-calendar ava_time-slot"></i>
                            </div>

                            <div className="col-6  pb-2">
                              <label className="ModelInputlabel">
                                Available Time Slots
                              </label>
                              <div className="form-select">
                                <div className="w-100">
                                  <select
                                    className="form-control h-small shadow1"
                                    name="edit_time_slot"
                                    id="edit_time_slot"
                                    onChange={this.onChangeEditTimeSlot}
                                    defaultValue={this.state.edit_time_slot}

                                  >
                                    <option value="">Select Slot</option>
                                    {this.state.SlotListDataByDate &&
                                      this.state.SlotListDataByDate.length > 0 ? (
                                      this.state.SlotListDataByDate.map(
                                        (item, index) => (

                                          <option key={index} value={item.slot_details_id}>
                                            {moment(item.start_date_time, ["HH:mm"]).format("hh:mm a")} TO {moment(item.end_date_time, ["HH:mm"]).format("hh:mm a")}

                                            {item.start_date_time} TO {item.end_date_time}
                                          </option>
                                        )
                                      )
                                    ) : (
                                      <option value={"Not Available"}>
                                        Not Available
                                      </option>
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div> */}
                          </>
                        )}

                        {/* <div className="col-6  pb-2">
                                <label>Lead Status</label>
                                  <div className="form-select">
                                    <div className="w-100">
                                      <select className="form-control h-small shadow1" name="edit_lead_status" id="edit_lead_status" onChange={this.onChangeEditLeadStatus} value={this.state.edit_lead_status}>
                                        <option value="1">Untouched</option>
                                        <option value="2"> Assign Field Executive</option>
                                        <option value="3"> Junk Lead</option>
                                        <option value="4">Follow Up</option>
                                        <option value="5">Not Reachable</option>
                                        <option value="6">Cold</option>
                                        <option value="7">Missed</option>
                                        <option value="8">Communication Done</option>
                                        <option value="9">Convert Lead</option>
                                      </select>
                                    </div>
                                  </div>
                                </div> */}
                      </div>
                      <label className="ModelInputlabel">Description</label>
                      <textarea
                        cols="30"
                        rows="5"
                        className="modalInputBox w-100 createPost shadow1"
                        placeholder="Description"
                        name="edit_description"
                        id="edit_description"
                        onChange={this.onChangeEditDescription}
                        defaultValue={this.state.edit_description}

                      ></textarea>

                      {/* {this.state.edit_lead_status == 3 ||
                        this.state.edit_lead_status == 4 ||
                        this.state.edit_lead_status == 5 ? (
                        <>
                          <div className="row">
                            <div className="col-6  pb-2"></div>

                            <div className="col-6  pb-2">
                              <p className="form-control h-small shadow1">
                                External Notification
                                <div className="custom-control custom-switch noti">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customSwitchesEN"
                                    defaultChecked={
                                      this.state.external_notification ===
                                        "true"
                                        ? true
                                        : false
                                    }
                                    onClick={(e) =>
                                      this.onChangeEditExternal(e)
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customSwitchesEN"
                                  ></label>
                                </div>
                              </p>
                            </div>
                          </div>
                        </>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="row mx-0 text-center text-md-right ">
                    <div className=" d-md-flex flex-wrap justify-content-end">
                      <button className="btn btn-primary modal-sub-btn btn-right shadow1 modal-sub-btn">
                        Update
                      </button>
                    </div>
                  </div>
                  <CheckButton
                    style={{ display: "none" }}
                    ref={(c) => {
                      this.checkBtn = c;
                    }}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
// App.js
// Displaying App.js.