import { DATA_SUCCESS, DATA_FAIL, SET_MESSAGE } from "./types";

import AdminFieldEnggService from "../services/AdminFieldEngg.Service";

export const FieldEnggAdd =
  (image, name, mobile, district, state, pincode, city,staff_status) => (dispatch) => {
    return AdminFieldEnggService.FieldEnggAdd(
      image,
      name,
      mobile,
      district,
      state,
      pincode,
      city,
      staff_status
      
    ).then(
      (response) => {
        dispatch({
          type: DATA_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: DATA_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const FieldEnggList = (pageNo,limit,name) => (dispatch) => {
  return AdminFieldEnggService.FieldEnggList(pageNo,limit,name).then(
    (response) => {
      dispatch({
        type: DATA_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DATA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const FieldEnggListEnquiry = (token,pageNo,limit) => (dispatch) => {
  return AdminFieldEnggService.FieldEnggListEnquiry(token,pageNo,limit).then(
    (response) => {
      dispatch({
        type: DATA_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DATA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const FieldEnggEdit = (id) => (dispatch) => {
  console.log("id++++ action", id);
  return AdminFieldEnggService.FieldEnggEdit(id).then(
    (response) => {
      dispatch({
        type: DATA_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DATA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const FieldEnggUpdate =
  (id, image, name, mobile, district, state, pincode, city,staff_status) => (dispatch) => {
    return AdminFieldEnggService.FieldEnggUpdate(
      id,
      image,
      name,
      mobile,
      district,
      state,
      pincode,
      city,
      staff_status
    ).then(
      (response) => {
        dispatch({
          type: DATA_SUCCESS,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });

        return Promise.resolve(response);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: DATA_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    );
  };

export const FieldEnggDelete = (id) => (dispatch) => {
  return AdminFieldEnggService.FieldEnggDelete(id).then(
    (response) => {
      dispatch({
        type: DATA_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: DATA_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
