import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API_URL;

let versionApiUrl;
// if(JSON.parse(localStorage.getItem("usersss"))){
// if(JSON.parse(localStorage.getItem("usersss")).ApiVersion=="v2"){
//   versionApiUrl="api/v2/admin/"
// }
// else{
//   versionApiUrl="api/v1/admin/"
// }
// }
versionApiUrl="api/v1/admin/"
class AdminProductService {
  ProductAdd(product_name, mrp, quantity, price, product_dec, product_img,brochure) {
    const insertData = new FormData();
    insertData.set("product_name", product_name);
    insertData.set("mrp", mrp);
    insertData.set("quantity", quantity);
    insertData.set("price", price);
    insertData.set("product_dec", product_dec);
    insertData.set("product_img", product_img);
    insertData.set("brochure", brochure);
    return axios
      .post(API_URL + versionApiUrl + "product/add", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  ProductQuantity(id, decrease_qty, increase_qty, note) {
    const updateData = new FormData();
    updateData.set("id", id);
    updateData.set("decrease_qty", decrease_qty);
    updateData.set("increase_qty", increase_qty);
    updateData.set("note", note);

    console.log("id---service", id);
    return axios
      .post(API_URL + versionApiUrl + "product/updatequantity", updateData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  ProductList(pageNo, limit,product_name) {
    
    return axios.get(API_URL + versionApiUrl + "product/list?pageNo="+pageNo+"&limit="+limit+"&product_name="+product_name,{headers:authHeader()}).then((response) => {
      return response.data;
    });
  }

  ProductListEnquiry(token,pageNo,limit) {
    return axios.get(API_URL + versionApiUrl + "product/list?pageNo="+pageNo+"&limit"+limit,{headers:{"x-access-token":token}}).then((response) => {
      return response.data;
    });
  }



  ProductEdit(id) {
    console.log("id++++ service", id);
    // var id=23
    var handeleinsert = {};
    handeleinsert.id = id;

    return axios
      .post(API_URL + versionApiUrl + "product/edit?id=1", handeleinsert,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  QuantityEdit(id) {
    console.log("id++++ service", id);
    // var id=23
    var handeleinsert = {};
    handeleinsert.id = id;

    return axios
      .post(API_URL + versionApiUrl + "product/edit?id=1", handeleinsert,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  ProductUpdate(id, product_name, mrp, price, product_dec, product_img,brochure) {
    const updateData = new FormData();
    updateData.set("id", id);
    updateData.set("product_name", product_name);
    updateData.set("mrp", mrp);
    updateData.set("price", price);
    updateData.set("product_dec", product_dec);
    updateData.set("product_img", product_img);
    updateData.set("brochure", brochure);

    return axios
      .post(API_URL + versionApiUrl + "product/update", updateData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  ProductDelete(id) {
    return axios
      .post(API_URL + versionApiUrl + "product/delete", { id },{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }
}

export default new AdminProductService();
