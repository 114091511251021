import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;
let versionApiUrl;
if(JSON.parse(localStorage.getItem("usersss"))){
if(JSON.parse(localStorage.getItem("usersss")).ApiVersion=="v2"){
  versionApiUrl="api/v2/admin/"
}
else{
  versionApiUrl="api/v1/admin/"
}
}
class AdminFieldEnggService {
  FieldEnggAdd(image, name, mobile, district, state, pincode, city,staff_status) {
    const insertData = new FormData();
    insertData.set("image", image);
    insertData.set("name", name);
    insertData.set("mobile", mobile);
    insertData.set("district", district);
    insertData.set("state", state);
    insertData.set("pincode", pincode);
    insertData.set("city", city);
    insertData.set("staff_status", staff_status);
   console.log("authHeader"+JSON.stringify(authHeader()));
  
   
    return axios
      .post(API_URL + versionApiUrl + "fieldengineer/add",insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  FieldEnggList(pageNo,limit,name) {
   
    return axios
      .get(API_URL + versionApiUrl + "fieldengineer/list?pageNo="+pageNo+"&limit="+limit+"&name="+name,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }
  FieldEnggListEnquiry(token,pageNo,limit) {
    return axios
      .get(API_URL + versionApiUrl+ "fieldengineer/list?pageNo="+pageNo+"&limit="+limit,{headers:{"x-access-token":token}})
      .then((response) => {
        return response.data;
      });
  }

  FieldEnggEdit(id) {
    console.log("id++++ service", id);
    // var id=23
    var handeleinsert = {};
    handeleinsert.id = id;

    return axios
      .post(API_URL + versionApiUrl + "fieldengineer/edit", handeleinsert,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  FieldEnggUpdate(id, image, name, mobile, district, state, pincode, city,staff_status) {
    const updateData = new FormData();
    updateData.set("id", id);
    updateData.set("image",image);
    updateData.set("name", name);
    updateData.set("mobile", mobile);
    updateData.set("district", district);
    updateData.set("state", state);
    updateData.set("pincode", pincode);
    updateData.set("city", city);
    updateData.set("staff_status", staff_status);
    return axios
      .post(API_URL + versionApiUrl + "fieldengineer/update", updateData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  FieldEnggDelete(id) {
    return axios
      .post(API_URL + versionApiUrl + "fieldengineer/delete", { id },{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }
}

export default new AdminFieldEnggService();
