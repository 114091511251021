import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

let versionApiUrl;
// if(JSON.parse(localStorage.getItem("usersss"))){
// if(JSON.parse(localStorage.getItem("usersss")).ApiVersion=="v2"){
//   versionApiUrl="api/v2/admin/"
// }
// else{
//   versionApiUrl="api/v1/admin/"
// }
// }
versionApiUrl="api/v1/admin/"

console.log(authHeader());
class AdminLeadService {
  LeadAdd(
    first_name,
    last_name,
    mobile,
    email_id,
    lead_source,
    product,
    description,
    dob,
    anniversary_date,
    lead_status,
   

  ) {
    const insertData = new FormData();
    
    insertData.set("first_name", first_name);
    insertData.set("last_name", last_name);
    insertData.set("mobile", mobile);
    insertData.set("email_id", email_id);
    insertData.set("lead_source", lead_source);
    insertData.set("product", product);
    insertData.set("description", description);
    insertData.set("dob", dob);
    insertData.set("anniversary_date", anniversary_date);
    insertData.set("lead_status", lead_status);
    insertData.set("add_by", "Admin");
  
console.log("authHeader"+authHeader());
    return axios
      .post(API_URL + versionApiUrl + "lead/add", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  EnquiryAdd(
    first_name,
    last_name,
    mobile,
    email_id,
    lead_source,
    product,
    description,
    lead_status,
    isAppointment,
    field_engineer,
    date_time,
    slot,
    slot_status,
    slot_id,
    token
    
  ) {
    const insertData = new FormData();
    insertData.set("first_name", first_name);
    insertData.set("last_name", last_name);
    insertData.set("mobile", mobile);
    insertData.set("email_id", email_id);
    insertData.set("lead_source", lead_source);
    insertData.set("product", product);
    insertData.set("description", description);
    insertData.set("lead_status", lead_status);

    insertData.set("isAppointment", isAppointment);
 
    insertData.set("field_engineer", field_engineer);
    insertData.set("date_time",date_time);
    insertData.set("slot",slot);
    insertData.set("slot_status",slot_status);
    insertData.set("slot_id",slot_id);
    insertData.set("add_by", "Enquiry Form");
  
console.log("authHeader"+authHeader());
    return axios
      .post(API_URL + versionApiUrl + "lead/enquiry_form", insertData,{headers:{"x-access-token":token}})
      .then((response) => {
        return response.data;
      });
  }




  SendMsg(
    email_id,
    mobile,
    message,
    file,
    subject
    
  ) {
    const insertData = new FormData();
    insertData.set("email_id", email_id);
    insertData.set("mobile", mobile);
    insertData.set("message", message);
    insertData.set("file", file);
    insertData.set("subject", subject);
  
    return axios
      .post(API_URL + versionApiUrl + "lead/sendmsg", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }


  LeadList() {
    let handleinsert={}
    handleinsert.from_days=1
    handleinsert.isseq=1
    handleinsert.pageNo=0
    handleinsert.limit=""
    return axios.post(API_URL + versionApiUrl + "lead/list",handleinsert ,{headers:authHeader()}).then((response) => {
      return response.data;
    });
  }

  LeadFilterCount() {
    return axios.get(API_URL + versionApiUrl + "lead/leadCount",{headers:authHeader()}).then((response) => {
      return response.data;
    });
  }

  LeadListwithPagi(
    pageNo, 
    limit,
    first_name,
    last_name,
    mobile,
    email_id,
    lead_source,
    product,
    status,
    follow_up_at,
    field_engineer,
    add_dt,
    add_by,
    mdf_dt,
    dtmf,
    untouched_lead,
    ivr_unanswered,
    ivr_answered,
    website_lead,
    facebook_lead,
    call_lead,
    from_date,
    to_date,
    isseq
    
  ) {
    const insertData = new FormData();
    insertData.set("pageNo",pageNo );
    insertData.set("limit", limit);
    insertData.set("first_name",first_name );
    insertData.set("last_name",last_name);
    insertData.set("mobile",mobile);
    insertData.set("email_id",email_id);
     insertData.set("lead_source",lead_source); 
     insertData.set("product",product); 
     insertData.set("lead_status",status); 
     insertData.set("follow_up_at",follow_up_at);
     insertData.set("field_engineer",field_engineer);
     insertData.set("add_dt",add_dt);
     insertData.set("add_by",add_by);
     insertData.set("mdf_dt",mdf_dt);
     insertData.set("dtmf",dtmf);
     insertData.set("untouched_lead",untouched_lead);
     insertData.set("ivr_unanswered",ivr_unanswered);
     insertData.set("ivr_answered",ivr_answered);
     insertData.set("website_lead",website_lead);
     insertData.set("facebook_lead",facebook_lead);
     insertData.set("call_lead",call_lead);
     insertData.set("from_date",from_date);
     insertData.set("to_date",to_date);
     insertData.set("isseq",isseq)

    return axios
      .post(API_URL + versionApiUrl + "lead/list", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  FieldEnggById(state_id, district_id) {
    //console.log("id++++ service", id);
    // var id=23
    // var getEnggbyId={}
    // getEnggbyId.id=id
    const insertData = new FormData();
    insertData.set("state", state_id);
    insertData.set("district", district_id);

    return axios
      .post(API_URL + versionApiUrl + "fieldengineer/listbyid", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LeadEdit(id) {
    console.log("id++++ service", id);
    // var id=23
    var handeleinsert = {};
    handeleinsert.id = id;

    return axios
      .post(API_URL + versionApiUrl + "lead/edit", handeleinsert,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LeadUpdate(
    id,
    first_name,
    last_name,
    mobile,
    email_id,
    lead_source,
    product,
    lead_status,
    description,
    // state,
    // district,
    field_engineer,
    department,
    user,
    // date,
    // time_slot,
    message,
    external_notification,
    dob,
    anniversary_date,
    date_time_fu
  ) {
    const updateData = new FormData();
    updateData.set("id", id);
    updateData.set("first_name", first_name);
    updateData.set("last_name", last_name);
    updateData.set("mobile", mobile);
    updateData.set("email_id", email_id);
    updateData.set("lead_source", lead_source);
    updateData.set("product", product);
    updateData.set("lead_status", lead_status);
    updateData.set("description", description);
    // updateData.set("state", state);
    // updateData.set("district", district);
    updateData.set("field_engineer", field_engineer);
    updateData.set("department", department);
    updateData.set("user", user);
    // updateData.set("date", date);
    // updateData.set("time_slot", time_slot);
    updateData.set("message", message);
    updateData.set("external_notification", external_notification);
    updateData.set('dob', dob);
    updateData.set('anniversary_date', anniversary_date);
    updateData.set('date_time_fu', date_time_fu);
    console.log("updated++++", updateData);
    return axios
      .post(API_URL + versionApiUrl + "lead/update", updateData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LeadDelete(del_id) {
    let id=JSON.stringify(del_id);
    //console.log("JSON.stringify(id",id);
    return axios
      .post(API_URL + versionApiUrl + "lead/delete", {id},{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LogEventAdd(lead_id, subject, message) {
    const insertData = new FormData();
    insertData.set("lead_id", lead_id);
    insertData.set("subject", subject);
    insertData.set("message", message);
    return axios
      .post(API_URL + versionApiUrl + "lead/addlog", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LeadMessage(message, numbers,from_date,to_date,isWhatsapp,isEmail) {
    // const insertData = new FormData();
    // //insertData.set("email_id",emails);
    // insertData.set("message", message);
    // insertData.set("mobile",JSON.stringify(numbers));
   // let numbers=numbers==""?"":numbers;
    let insertData={}
    insertData.message=message
    insertData.mobile=numbers
    insertData.from_date=from_date
    insertData.to_date=to_date
    insertData.isWhatsapp=isWhatsapp
    insertData.isEmail=isEmail
    return axios
      .post(API_URL + versionApiUrl + "lead/sendmsg", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LeadEmailMessage(emails, subject, message,from_date,to_date,isWhatsapp,isEmail) {
    const insertData = new FormData();
    insertData.set("email_id", emails);
    insertData.set("subject", subject);
    insertData.set("message", message);
    insertData.set("from_date",from_date);
    insertData.set("to_date",to_date);
    insertData.set("isWhatsapp",isWhatsapp);
    insertData.set("isEmail",isEmail);
    //insertData.set("mobile", numbers);
    return axios
      .post(API_URL + versionApiUrl + "lead/sendmsg", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LogEventList(id) {
    const insertData = new FormData();
    insertData.set("lead_id", id);
    console.log("lead_ id --- service", id);
    return axios
      .get(API_URL + versionApiUrl + "lead/logeventlist?lead_id=" + id + "",{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LogEventUpdate(lead_id, subject, message) {
    const updateData = new FormData();
    updateData.set("id", lead_id);
    updateData.set("subject", subject);
    updateData.set("message", message);

    return axios
      .post(API_URL + versionApiUrl + "lead/updatelog", updateData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LogEventEdit(id) {
    console.log("id++++ service", id);
    // var id=23
    var handeleinsert = {};
    handeleinsert.id = id;

    return axios
      .post(API_URL + versionApiUrl + "lead/loginfo", handeleinsert,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LogTaskAdd(lead_id, subject, message, date_time, external) {
    const insertData = new FormData();
    insertData.set("lead_id", lead_id);
    insertData.set("subject", subject);
    insertData.set("message", message);
    insertData.set("date_time", date_time);
    insertData.set("external", external);
    return axios
      .post(API_URL + versionApiUrl + "lead/addtask", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LogTaskList(id) {
    const insertData = new FormData();
    insertData.set("lead_id", id);
    return axios
      .get(API_URL + versionApiUrl + "lead/logtasklist?lead_id=" + id + "",{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LogTaskEdit(id) {
    console.log("id++++ service", id);
    // var id=23
    var handeleinsert = {};
    handeleinsert.id = id;

    return axios
      .post(API_URL + versionApiUrl + "lead/loginfo", handeleinsert,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LogTaskUpdate(lead_id, subject, message, date_time, external) {
    const updateData = new FormData();
    updateData.set("lead_id", lead_id);
    updateData.set("subject", subject);
    updateData.set("message", message);
    updateData.set("date_time", date_time);
    updateData.set("external", external);

    return axios
      .post(API_URL + versionApiUrl + "lead/updatetask", updateData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  AttachmentAdd(lead_id, attachment) {
    const insertData = new FormData();
    insertData.set("lead_id", lead_id);
    insertData.set("attachment", attachment);

    return axios
      .post(API_URL + versionApiUrl + "lead/addattachment", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  AttachmentList(id) {
    const insertData = new FormData();
    insertData.set("lead_id", id);
    return axios
      .get(API_URL + versionApiUrl + "lead/listattachment?lead_id=" + id + "",{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LogEventDelete(id) {
    return axios
      .post(API_URL + versionApiUrl + "lead/logdelete", { id },{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LogTaskDelete(lead_id) {
    return axios
      .post(API_URL + versionApiUrl + "lead/taskdelete", { lead_id },{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  AttachmentDelete(lead_id) {
    return axios
      .post(API_URL + versionApiUrl + "lead/attachmentdelete", { lead_id },{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  TransferLead(lead_id, transfer_to) {
    const insertData = new FormData();
    insertData.set("lead_id", lead_id);
    insertData.set("transfer_to", transfer_to);

    return axios
      .post(API_URL + versionApiUrl + "lead/transferlead", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LeadStatusList() {
    return axios
      .get(API_URL + versionApiUrl + "lead/leadstatus",{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LeadTagList(lead_id) {
    const insertData = new FormData();
    insertData.set("lead_id", JSON.stringify(lead_id));

    return axios
      .post(API_URL + versionApiUrl + "leadtag/list", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LeadAddTag(lead_id, tag_name, tag_overwrite,tag_id) {
    // const insertData = new FormData();
    // insertData.set("lead_id", JSON.stringify(lead_id));
    // insertData.set("tag_name", tag_name);
    // insertData.set("isoverWrite", tag_overwrite);
    let insertData={}
    insertData.lead_id=JSON.stringify(lead_id)
  
    insertData.tag_name=tag_name
    insertData.isoverWrite=tag_overwrite
    insertData.tag_id=tag_id

    return axios
      .post(API_URL + versionApiUrl + "leadtag/addtag", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LeadRemoveTag(tag_id) {
    const insertData = new FormData();
    insertData.set("tag_id", tag_id);

    return axios
      .post(API_URL + versionApiUrl + "leadtag/delete", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  LeadAddCampaign(lead_id,arr, campaignName,templateId,ofiicial_template,isEmail,isWhatsapp,isSMS,official_whatsapp,from_date,to_date) {
    let leadid=lead_id==""?"":JSON.stringify(lead_id)
    const insertData = new FormData();
    insertData.set("lead_id", leadid);
    insertData.set("campaign", campaignName);
    insertData.set("details", JSON.stringify(arr));
   insertData.set("template", templateId);
   insertData.set("ofiicial_template", ofiicial_template);
   insertData.set("isEmail", isEmail);
   insertData.set("isWhatsapp", isWhatsapp);
   insertData.set("isSMS", isSMS);
   insertData.set("official_whatsapp", official_whatsapp);
   insertData.set("from_date", from_date);
   insertData.set("to_date", to_date);
   if(official_whatsapp==1){
    var camp_url= versionApiUrl + "campaign/officialquickcampaign"
  }
   else{
    var camp_url= versionApiUrl + "campaign/addquickcampaign"
   }
    return axios
    .post(API_URL + camp_url, insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  AppointmentAdd(lead,slot_status,field_engineer,date_time,slot,slot_id) {
    const insertData = new FormData();
    insertData.set("lead", lead);
    insertData.set("field_engineer", field_engineer);
    insertData.set("date_time",date_time);
    insertData.set("slot",slot);
    insertData.set("slot_status",slot_status);
    insertData.set("slot_id",slot_id);
    insertData.set("official_whatsapp", JSON.parse(localStorage.getItem("usersss")).official_whatsapp);
    if(JSON.parse(localStorage.getItem("usersss")).official_whatsapp==1){
      var appoint_url= versionApiUrl + "appointment/sendAppointmentBook"
    }
     else{
      var appoint_url= versionApiUrl + "appointment/add"
     }
   
     return axios
      .post(API_URL + appoint_url, insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }
SendAppointmentBooked( lead ,official_whatsapp){
   const insertData = new FormData();
    
   insertData.set("lead", lead);
  //  insertData.set("official_whatsapp", official_whatsapp);
   if(JSON.parse(localStorage.getItem("usersss")).official_whatsapp==1){

   return axios
   .post(API_URL + versionApiUrl + "appointment/sendAppointmentBook",insertData,{headers:authHeader()})
   .then((response) => {
     return response.data;
   });
  }
}

  AppointmentListbydate(pageNo,limit,isseq) {
   // const insertData = new FormData();
    
   // insertData.set("date", date);
     return axios
       .get(API_URL + versionApiUrl + "appointment/todaysAppointment?pageNo="+pageNo+"&limit="+limit+"&isseq="+isseq,{headers:authHeader()})
       .then((response) => {
         return response.data;
       });
   }


  AppointmentList(id,field_engineer) {
   const insertData = new FormData();
   insertData.set("lead", id);
   insertData.set("field_engineer", field_engineer);
  ;
    return axios
      .get(API_URL + versionApiUrl + "appointment/list?lead="+ id +"&field_engineer="+ field_engineer,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  UploadLeadExcel(excle) {
    const insertData = new FormData();
    insertData.set("excle", excle);
   
  
    return axios
      .post(API_URL + versionApiUrl + "lead/upload", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  TranferLeadListById(id) {
    
    const insertData = new FormData();
    insertData.set("lead_id", id);
   

    return axios
      .post(API_URL + versionApiUrl + "lead/tranferlist", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

   LeadReportList(start_date,end_date) {
  //  const insertData = new FormData();
  //  insertData.set("lead", id);
  //  insertData.set("field_engineer", field_engineer);
  // ;
    return axios
      .get(API_URL + versionApiUrl + "reports/leadreport?start_date="+ start_date +"&end_date="+ end_date,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  ReportAppointment(staff,start_date,end_date) {
    
    const insertData = new FormData();
    insertData.set("staff_id",JSON.stringify(staff));
    insertData.set("start_date", start_date);
    insertData.set("end_date", end_date);
    return axios
      .post(API_URL + versionApiUrl + "reports/Appointment", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  ReportRevenue(product_id,start_date,end_date) {
    
    const insertData = new FormData();
    insertData.set("product_id",JSON.stringify(product_id));
    insertData.set("start_date", start_date);
    insertData.set("end_date", end_date);
    return axios
      .post(API_URL + versionApiUrl + "reports/product_report", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  MailReport(start_date,end_date) {
    
    const insertData = new FormData();
    insertData.set("start_date", start_date);
    insertData.set("end_date", end_date);
   
    return axios
      .post(API_URL + versionApiUrl + "reports/mailreposrt", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  MailAppointmentReport(staff_id,start_date,end_date) {
    
    const insertData = new FormData();
    insertData.set("staff_id",JSON.stringify(staff_id));
    insertData.set("start_date", start_date);
    insertData.set("end_date", end_date);
   
    return axios
      .post(API_URL + versionApiUrl + "reports/mailAppointmentreposrt", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  MailRevenueReport(product_id,start_date,end_date) {
    
    const insertData = new FormData();
    insertData.set("product_id",JSON.stringify(product_id));
    insertData.set("start_date", start_date);
    insertData.set("end_date", end_date);
   
    return axios
      .post(API_URL + versionApiUrl + "reports/product_report_mail", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  AddNote(lead_id,note,add_by) {
    
    const insertData = new FormData();
    insertData.set("lead_id", lead_id);
    insertData.set("note", note);
    insertData.set("add_by", add_by);
   
    return axios
      .post(API_URL + versionApiUrl + "lead/leadnoteAdd", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  NoteList(mobile) {
    
    const insertData = new FormData();
    insertData.set("mobile", mobile);
   
   
    return axios
      .post(API_URL + versionApiUrl + "lead/leadnotelist", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  EditConfirmAppointment(id,username) {
    
    const insertData = new FormData();
    insertData.set("appointment_id", id);
    insertData.set("username", username);
   
   
    return axios
      .post(API_URL + versionApiUrl + "appointment/editAppointment_for_confirmation", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  ConfirmAppointment(id,username) {
    
    const insertData = new FormData();
    insertData.set("appointment_id", id);
    insertData.set("username", username);
   
   
    return axios
      .post(API_URL + versionApiUrl + "appointment/confirmAppointment", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  SendLeadCsv(lead_id,from_date,to_date, first_name,
    last_name,
    mobile,
    email_id,
    follow_up_at,
    dob,
    anniversary_date,
   intrested_product,
    add_dt,
    mdf_dt,
    isseq) {
    let leadid= lead_id==""?lead_id:JSON.stringify(lead_id)
    const insertData = new FormData();
    insertData.set("lead_id",leadid);
    insertData.set("from_date", from_date);
    insertData.set("to_date", to_date);
    insertData.set("first_name",first_name);
    insertData.set("last_name", last_name);
    insertData.set("mobile", mobile);
    insertData.set("email_id",email_id);
    insertData.set("follow_up_at", follow_up_at);
    insertData.set("dob", dob);
    insertData.set("anniversary_date",anniversary_date);
    insertData.set("intrested_product", intrested_product);
    insertData.set("add_dt", add_dt);
    insertData.set("mdf_dt",mdf_dt);
    insertData.set("isseq", isseq);
    
    //insertData.set("username", username);
   
   
    return axios
      .post(API_URL + versionApiUrl + "lead/sendleadcsv", insertData,{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  AppointmentNotifiaction() {debugger
    
    // const insertData = new FormData();
    // insertData.set("appointment_id", id);
    // insertData.set("username", username);
   
   
    return axios
      .get(API_URL + versionApiUrl + "appointment/notification",{headers:authHeader()})
      .then((response) => {
        return response.data;
      });
  }
}

export default new AdminLeadService();
