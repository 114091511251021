import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth } from 'firebase/auth';

var firebaseConfig = {
  // apiKey: "AIzaSyDGor0dVOq2bZBBojnrXl7Wy673mHEqL-Y",
  // authDomain: "dev-test-2860e.firebaseapp.com",
  // projectId: "dev-test-2860e",
  // storageBucket: "dev-test-2860e.appspot.com",
  // messagingSenderId: "469488545905",
  // appId: "1:469488545905:web:e827d5670564cbccb1abd0",
  // measurementId: "G-D7N6DWJ79R",

  apiKey: "AIzaSyCRgDouO1w3tScmbDh9923XjLQshvzoSYk",

  authDomain: "lead2business-55f54.firebaseapp.com",

  projectId: "lead2business-55f54",

  storageBucket: "lead2business-55f54.appspot.com",

  messagingSenderId: "1059220979101",

  appId: "1:1059220979101:web:2e6d9a156b0401833d0374",

  measurementId: "G-SZHV3CVK69"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
// const messaging = firebaseApp.messaging();
const auth = getAuth(firebaseApp);
  console.log("firebase token",messaging);
  
export const fetchToken = (setTokenFound) => {
  //alert(setTokenFound)
  return getToken(messaging, {
    vapidKey:"BDDwo3ohDR35UgAyneT_kJlyEe4bFxyXuvwC8UsXmh3SeAlX6OmCyj3_ilNXDvuUE9fqkzQE0qITnt3CbWWzn0c",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        localStorage.setItem("firebase_token", currentToken)
        setTokenFound = true;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound = false;
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err.message);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);

    });
  });





export { auth }
